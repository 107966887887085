<template>
  <AdminPageFrame class="w-main-container">
  <PageTitle>
    {{ $route.params.id ? trans('PAGE_TITLE:PartnerEdit') : trans('PAGE_TITLE:PartnerAdd') }}
  </PageTitle>
  <t-card class="w-full">
	  <ValidationObserver class="flex flex-col gap-3" ref="partnerForm" v-slot="{ invalid }">
	    <FieldWrapper :label="trans('LABEL:Name')">
	      <TextField rules="required" v-model="partner.name"/>
	    </FieldWrapper>
	    <FieldWrapper :label="trans('LABEL:Email')">
	      <TextField rules="required|email" v-model="partner.email"/>
	    </FieldWrapper>
	    <FieldWrapper :label="trans('LABEL:NIP')">
	      <TextField rules="required|max:10" v-model="partner.nip"/>
	    </FieldWrapper>
	    <FieldWrapper :label="trans('LABEL:Image')">
	      <div class="mb-3" v-if="partnerImagePreview">
	        <img class="h-60 object-contain" :src="partnerImagePreview" />
	      </div>
	      <input v-on:change="addImagePath($event, 'picture_placeholder_thumbnail')" type="file" />
	    </FieldWrapper>
      <FieldWrapper :label="trans('LABEL:Summary')">
        <div class="partner-summary-wrapper">
          <quill-editor
            ref="partnerSummaryEditor"
            v-model="partner.summary"
            :options="editorOptions"
          />
        </div>
      </FieldWrapper>
      <FieldWrapper :label="trans('LABEL:Description')">
		    <div class="partner-description-wrapper">
			    <quill-editor
            ref="partnerDescriptionEditor"
            v-model="partner.description"
            :options="editorOptions"
			    />
		    </div>
	    </FieldWrapper>
	    <FieldWrapper :label="trans('LABEL:PostalAddress')">
	      <TextField rules="required" v-model="partner.postal_address"/>
	    </FieldWrapper>
	    <FieldWrapper :label="trans('LABEL:UrlWww')">
	      <TextField v-model="partner.www_url"/>
	    </FieldWrapper>
	    <FieldWrapper :label="trans('LABEL:UrlFacebook')">
	      <TextField v-model="partner.facebook_url"/>
	    </FieldWrapper>
	    <FieldWrapper :label="trans('LABEL:UrlInstagram')">
	      <TextField v-model="partner.instagram_url"/>
	    </FieldWrapper>
	    <FieldWrapper :label="trans('LABEL:UrlTikTok')">
	      <TextField v-model="partner.tiktok_url"/>
	    </FieldWrapper>
		  <FieldWrapper :label="trans('LABEL:UrlYoutube')">
			  <TextField v-model="partner.youtube_url"/>
		  </FieldWrapper>
		  <FieldWrapper :label="trans('LABEL:UrlLinkedin')">
			  <TextField v-model="partner.linkedin_url"/>
		  </FieldWrapper>
	    <FieldWrapper :label="trans('LABEL:CommissionPercentage')">
	      <TextField field-type="number" rules="required" v-model="partner.commission_percentage"/>
      </FieldWrapper>
	    <FieldWrapper>
	      <CheckboxField v-model="partner.active" :label="trans('LABEL:IsActive')"/>
	    </FieldWrapper>
	    <div class="flex flex-row justify-end">
	      <Button :text="trans('BUTTON:Save')" :loading="partnersSaving" @clicked="storePartnerAction"/>
	    </div>
	  </ValidationObserver>
  </t-card>
  </AdminPageFrame>
</template>

<script>
import AdminPageFrame from "@/views/content/admin/components/AdminPageFrame";
import Button from "@/utils/crud/components/Button";
import FieldWrapper from "@/utils/crud/components/field-types/components/FieldWrapper";
import PageTitle from "@/layouts/components/pages/PageTitle";
import TextField from "@/utils/crud/components/field-types/Text";
import {mapActions, mapGetters} from "vuex";
import CheckboxField from "@/utils/crud/components/field-types/Checkbox.vue";
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  name: "PartnerShow",
  components: {
    AdminPageFrame,
    Button,
    FieldWrapper,
    PageTitle,
    TextField,
    CheckboxField,
	  quillEditor
  },
  data() {
    return {
      partner: {
        name: '',
        email: '',
        nip: '',
        summary:'',
        description: '',
        postal_address: '',
        tiktok_url: '',
        facebook_url: '',
        youtube_url: '',
        linkedin_url: '',
        active: false,
        image: '',
        commission_percentage: '',
      },
	    editorOptions: {},
      partnerImagePreview: null,
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler(val) {
        if(val) {
          this.fetchShowPartnerRequest(val);
        }
      }
    },
    partnerLoaded(val) {
      if(val) {
        this.partner = structuredClone(this.partnerData)
        this.partnerImagePreview = this.partnerData.image;
	      delete this.partner.image;
      }
    }
  },
  computed: {
    ...mapGetters('adminPartners', ['partnersSaving', 'partnerLoaded', 'partnerData'])
  },
  methods: {
    ...mapActions('adminPartners', ['storePartnerData', 'fetchShowPartnerRequest', 'updatePartnerData']),
    addImagePath(event) {
      this.$set(this.partner, 'image', event.target.files[0])

      this.partnerImagePreview = URL.createObjectURL(event.target.files[0]);
    },
    storePartnerAction() {
      let formData = new FormData();
      formData.append('active', this.partner.active ? 1 : 0);
      formData.append('commission_percentage', this.partner.commission_percentage);
	    formData.append('tiktok_url', this.partner.tiktok_url ?? '');
	    formData.append('facebook_url', this.partner.facebook_url ?? '');
	    formData.append('instagram_url', this.partner.instagram_url ?? '');
	    formData.append('www_url', this.partner.www_url ?? '');
	    formData.append('youtube_url', this.partner.youtube_url ?? '');
	    formData.append('linkedin_url', this.partner.linkedin_url ?? '');
	    formData.append('name', this.partner.name ?? '');
	    formData.append('email', this.partner.email ?? '');
	    formData.append('nip', this.partner.nip ?? '');
      formData.append('summary', this.partner.summary ?? '');
	    formData.append('description', this.partner.description ?? '');
	    formData.append('postal_address', this.partner.postal_address ?? '');

	    if(this.partner.image) {
        formData.append('image', this.partner.image);
      }
      if(this.$route.params.id) {
        formData.append('id', this.$route.params.id);
        this.updatePartnerData(formData);
        return;
      }
      this.storePartnerData(formData);
    }
  }
}
</script>

<style lang="scss">
.partner-description-wrapper {
	.ql-editor {
		height: 250px;
	}
}
.partner-summary-wrapper {
  .ql-editor {
    height: 50px;
  }
}

</style>
